/** DO NOT EDIT MANUALLY. UPDATED BY web1-vue-cli. **/

/** start module imports **/
/** end module imports **/

const routes = [
   /** start module spread **/
   /** end module spread **/
];

export default routes;
