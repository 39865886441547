<template>
  <div id="app">
      <div class="app__view">
         <h2 class="app__view-title" v-if="page_title">{{page_title}}</h2>

         <!-- /* Non-blocking alerts */ -->
         <vue-bootstrap-flash-notifications></vue-bootstrap-flash-notifications>

         <div class="app__view-route">
            <router-view :key="$route.fullPath"/>
         </div>
      </div>

  </div>
</template>

<script>
'use strict';
// import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';

export default {
   emits: [],
   
   components: {
   },// /components{}

   computed:{
      ...mapGetters([
         'page_title',
      ]),
   },// /computed{}

   mounted (){
   },// /mounted()
};
</script>


<style scoped>

.app__view
{
   /* max-width: 50em; */
   max-width: 40em;
   margin: auto;
   padding: 0.3em;
   margin-top: 3em;
}

.app__view__route
{
   padding: 0.3em;
}

.webstyle-header
{
   margin-bottom: 1em;
}

.campaign-info
{
   margin-bottom: 0.3em;
}

/* Small only */
@media screen and (max-width: 767px)
{
   .webstyle-header
   {
      margin-bottom: 0.3em;
   }
}

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>

<style>

body
{
   background-color: #343a40 !important;
}
</style>
