import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/vendor/fontawesome/fontawesome-free-6.0.0-web/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
const bootstrap = require( 'bootstrap' ); /* jshint ignore:line */
import VueBootstrapBlockingNotifications from '@/repo/vue-bootstrap-blocking-notifications';
import VueBootstrapFlashNotifications from '@/repo/vue-bootstrap-flash-notifications';



const app = createApp(App);
app
   .use(VueBootstrapFlashNotifications)
   .use(VueBootstrapBlockingNotifications)
   .use(store)
   .use(router)
   .mount('#app');
app.config.globalProperties.$bootstrap = bootstrap;
