import { createRouter, createWebHashHistory } from 'vue-router';
import module_routes from '@/modules/routes';
import Error404 from '@/views/Error404';

// Generate an array of routes from the module list.
const a_module_routes = (function(){
   var n, a = [];
   for( n in module_routes ){
      a = a.concat( module_routes[ n ] );
   }// /for()
   return a;
}());

const routes = [
   {
      path: '/',
      name: 'default',
      component: () => import('@/views/Default'),
   },
   {
      path: '/forgot_password',
      name: 'forgot_password',
      component: () => import('@/views/ForgotPassword'),
   },
   {
      path: '/forgot_contact',
      name: 'forgot_contact',
      component: () => import('@/views/ForgotContact'),
   },
   
   ...a_module_routes,

   {
      path: '/:catchAll(.*)',
      component: Error404,
   },
];

const router = createRouter({
   history: createWebHashHistory(),
   routes
});

export default router;
