import { createStore } from 'vuex';
import axios from 'axios';
import request_apirid from '@/lib/request-apirid';

export default createStore({
   state: {
      client_id: window.Server.config.session.client?.clientid || 0,
      app_title: window.Server.config.app_title,
      page_title: null,
      server: window.Server,
      base_url: window.Server.config.base_url,
      enable_fcc_advisory: window.Server.config.enable_fcc_advisory,
      reason_error_message: window.Server.config.reason_error_message,
      // reason_error_message:'You do not have permission to access the grand castle!',
      window_history: window.history,

      
   },// /state{}

   mutations: {
      SET_PAGE_TITLE ( state, c_title ){
         state.page_title = c_title;
      },// /SET_PAGE_TITLE()

      SET_RESOURCE_LIST ( state, a_resources ) {
         state.resources = a_resources;
      },// /SET_RESOURCE_LIST()
   },// /mutations{}

   actions: {
      login_main( context, c_job_id ){
         const c_apirid = request_apirid();
         const c_name = 'loginmain';
         const c_url = `/${context.state.client_id}/${c_name}.wr?apirid=${c_apirid}`;

         return new Promise(function( resolve, reject ){
            axios({
               method: 'post',
               url: c_url,
               data: new URLSearchParams({
                  apirid: c_apirid,
                  job_id: c_job_id,
               }).toString(),
            })
            .then(( o_response ) => {
               const o_result = o_response.data;
               var o_result_error;

               // The server received our request and provided us with a valid JSON
               // response. Now we check if the web1 server's response contains an error 
               // message.
               if (o_result.error) {
                  // The server sent us an error message. Display the message to the user.
                  o_result_error = {
                     code: 500,
                     message: c_name.concat( ' Error: ', o_result.error.message ),
                     source: Object.assign( {}, o_result.error ),
                  };
                  return reject( o_result_error );
               }
               
               // At this point the server has provided us with the data we asked for.
               return resolve( o_result );
            })
            .catch(( o_error ) => {
               // This can happend when web1 randomly returns a "no output" html error.
               const o_result_error = {
                  code: 500,
                  message: c_name.concat(' Failed: ', o_error.message ),
                  source: Object.assign( {}, o_error ),
               };
               return reject( o_result_error );
            });
         });
      },// /login_main()

      set_page_title ( context, c_title ){
         context.commit( 'SET_PAGE_TITLE', c_title );
      },// /set_page_title()
   },// /actions{}

   getters: {
      app_title( state ){
         return state.app_title;
      },// /app_title()

      client_id( state ){
         return state.client_id;
      },// /client_id()

      page_title( state ){
         return state.page_title;
      },// /page_title()

      base_url( state ){
         return state.base_url;
      },// /base_url()

      enable_fcc_advisory( state ){
         return state.enable_fcc_advisory;
      },// /enable_fcc_advisory()
      
      reason_error_message( state ){
         return state.reason_error_message;
      },// /reason_error_message() 

      window_history( state ){
         return state.window_history;
      },// /window_history() 
   },// /getters{}
});
